import React, { Component } from 'react';
import './css/App.css';

import Main from './Main';
// import Concept from './Concept';
import Message from './Message';
// import Contact from './Contact';
// import Test from './test';

import { 
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Main} />
          {/* <Route exact path='/concept' component={Concept} /> */}
          {/* <Route exact path='/contact' component={Contact} /> */}
          {/* <Route path='/message/show/:id' component={Message} /> */}
          <Route path='/message/show' component={Message} />
          {/* <Route exact path='/test' component={Test} /> */}
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
