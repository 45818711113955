import React from 'react';
import Modal from 'react-modal';
import './css/Modal.css';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

class Modalwrap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: true,
      encPass:'',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }


  onSubmit(e){
    e.preventDefault();
    return this.props.passData(this.state.encPass);
  } 

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <p>パスワードを入力ください。</p>
          <form onSubmit={this.onSubmit}>
            <input 
                type="text" 
                placeholder="password" 
                className="modal_input"
                value={this.state.encPass}
                onChange = { e => this.setState({encPass: e.target.value})}
                />
            <div className="btn_wrapper">
                <button type="submit" className="modal_btn">送信</button>
                <button  className="modal_btn" onClick={this.closeModal}>戻る</button>
            </div>
          </form>
          
        </Modal>
      </div>
    );
  }
}

export default Modalwrap;