import React, { Component } from 'react';
import './css/BackSp.css';

class BackSp extends Component {
    render() {     
        return(
            <div className="items_intoroduction">
                <p className="intro_sub_title">PRODUCTS</p>
                <p className="intro_title" id="items">商品一覧</p>
                <div className="item">
                    <div className="pinky_a"></div>
                    <div className="item_pinky">         
                        <p className="item_name">PINKY RING</p>
                        <p className="item_sub_name">ピンキーリング</p>
                        <p className="item_discription">
                        この幸せが永遠に続くように。<br/>
                        大好きなあの人を想いながら、<br/>
                        ピンキーリングに願いを込める。
                        </p>
                    </div>
                    <div className="back_pinky">
                        <div className="pinky_b"></div>
                        <div className="pinky_c"></div>
                    </div>
                </div>
                <div className="item">
                    <div className="engage_a"></div>
                    <div className="item_engage">
                        <p className="item_name">ENGAGEMENT<br/>RING</p>
                        <p className="item_sub_name">婚約指輪</p>
                        <p className="item_discription">
                        あの人に最高のプレゼントを届ける。<br/>
                        指輪の中に秘めた、<br/>
                        特別なメッセージと共に。
                        </p>
                    </div>
                    <div className="back_engage">
                        <div className="engage_b"></div>
                        <div className="engage_c"></div>
                    </div>
                </div>
                <div className="item">
                    <div className="marriage_a"></div>
                    <div className="item_marrige">
                        <p className="item_name">MARRIAGE<br/>RING</p>
                        <p className="item_sub_name">結婚指輪</p>
                        <p className="item_discription">
                        大切な時間を育んでいく。<br/>
                        愛を誓い合った二人の指輪の中に、<br/>
                        日々の思い出を刻みながら。
                        </p>
                    </div>
                    <div className="back_marriage">
                        <div className="marriage_b"></div>
                        <div className="marriage_c"></div>
                    </div>
                </div>
                <div className="item">
                    <div className="baby_a"></div>
                    <div className="item_baby">
                        <p className="item_name">BABY RING</p>
                        <p className="item_sub_name">ベビーリング</p>
                        <p className="item_discription">
                        これからの幸せな日常を願い、<br/>
                        子供の成長の記録を<br/>
                        リングへと綴る。
                        </p>
                    </div>
                    <div className="back_baby">
                        <div className="baby_b"></div>
                        <div className="baby_c"></div>
                    </div>
                </div>
            </div>
        )                   
    }
}

export default BackSp;


