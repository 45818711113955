import React, {
    Component
} from 'react';
import './css/App.css';
import Modalwrap from './modal';
import './css/Message.css';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import entities from 'entities';
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import Web3 from 'web3';
import abi from './ethereum/Message.json';

const provider = new Web3.providers.HttpProvider(
    "https://mainnet.infura.io/v3/e3ac7a6c5c754bde9c8bcc484bf9fe35"
);
// "https://mainnet.infura.io/v3/30861637e2f645f1937dd6c38e871a3d"
let web3 = new Web3(provider);

class Message extends Component {

    constructor() {
        super()

        var pathname= window.location.pathname;
        var address = pathname.substr(14);

        this.state = {
            contractAddress: address,
            message: '',
            status: true,
            encrypt: false,
            encPass: ''
        }
        this.getMessage = this.getMessage.bind(this);
    }

    async componentWillMount() {
        await this.getMessage();
    }

    setPass(Password) {
        var encResult = AES.decrypt(this.state.message, Password,{
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          }).toString(CryptoJS.enc.Utf8)
        this.setState({
            message: encResult,
            encrypt: false,
        })
      }

    async getMessage() {
        const instance = new web3.eth.Contract(
            abi,
            this.state.contractAddress
        );
        var message = await instance.methods.getMessage().call()
        this.setState({
            message: message,
            status: true,
        })
    }
    render() {
        const loading = (
            <div className="loader">Loading...</div>
        );
        const message = (
            <ReactMarkdown div className="get_message_area">
                { this.state.message }
            </ReactMarkdown>
        );

        return(
            <div className="msg_wrapper">
                <div className="m_container">
                    <div className="title_area">
                    { this.state.encrypt ?　<Modalwrap message={this.state.message} passData={(x) => { this.setPass(x); }}/> : false}
                        <p>メッセージ</p>
                        <p className="text-contract">{ this.state.contractAddress }</p>
                    </div>
                    { this.state.status ? message : loading }
                </div>
                <div className="home_area">
                    <a href="/" className="home">ホームに戻る</a>
                </div>
                <div className="footer">
                    <a href="/"><div className="footer_logo"></div></a>
                    <div className="fotter_menu">
                            <span><a className="fotter_menu_none" id="border_none" href="#message">メッセージを読む</a></span>
                            {/* <span><a className="fotter_menu_left" href="/concept">コンセプト</a></span> */}
                            <span><a className="fotter_menu_left" href="https://issho.io#items">商品一覧</a></span>
                            <span><a className="fotter_menu_left"　href="mailto:info@issho.io?subject=【ISSHO | ホームページから問い合わせ】">お問い合わせ</a></span>
                    {/* <span><a className="fotter_menu_left" href="/contact">お問い合わせ</a></span> */}
                            <span><a className="fotter_menu_left" href="https://company.issho.io">会社概要</a></span>
                    </div>
                    <div className="sns">
                        <a href="https://www.facebook.com/issho.io/" target="_blank"><div className="facebook"></div></a>
                        <a href="https://www.instagram.com/issho.io/" rel="noopener noreferrer"><div className="instagram"></div></a>
                        <a href="https://twitter.com/issho_io" rel="noopener noreferrer"><div className="twitter"></div></a>
                    </div>
                    <p className="copy">Copyright © <a href="https://company.issho.io" className="">ISSHO, inc.</a> All Rights Reserved</p>
                </div>
            </div>
        )
    }
}

export default Message;

