import React, { Component } from 'react';
import axios from 'axios';
import './css/App.css';
import './css/Message.css';


import logo from './image/logo_white_large.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import TypeWriter from 'react-typewriter';
import BackWeb from './BackWeb';
import BackSp from './BackSp';

var domain = "https://api-v1.issho.io";
var obj = {};

class Main extends Component {
  // constructor(props) {
  constructor(props) {
    super(props);
      this.state = {
        contractAddress: '',
        message: '',
        status: false,
        isLoading: false,
        contact_name: '',
        contact_belong: '',
        contact_email: '',
        contact_purpose: '',
        contact_detail:'',
        contact_status: 0,  //0: 以前, 1: 成功, 2: 失敗,
        copied: false,
        pinky_value: "0x63d2d2c6d03b63e2f3a65867befa6881cf6318e6",
        engage_value: "0x3f88df9ad7a6fdaa700dd5263853326e4756e62f",
        marriage_value_men: "0xa1719023ffa9956e635b28fdcf7ff2a4cf2e933e",
        marriage_value_women: "0x0aa70bc57a2ff2750580f242ed77e3ba1bdbdda4",
        baby_value: "0x1449bffa08d35383fca34c578903f5196015a254"
      }
    this.onSubmit = this.onSubmit.bind(this);
    this.onSend = this.onSend.bind(this);
  }

  componentWillMount() {
    var pElement = document.body;
    var clientWidth = pElement.clientWidth ;
    obj.clientWidth = clientWidth;
    // console.log({clientWidth});
  }

  onSubmit(event) {
    event.preventDefault();
    // this.setState({
    //   message: '', 
    //   isLoading: true,
    //   status: false
    // });

    // const url = domain + "/v1/message";
    // var params = new URLSearchParams();
    // params.append('contractAddress', this.state.contractAddress);
    // axios.post(url, params)
    // .then(function(response) {
    //   console.log(response);
    //     this.setState({
    //         message: response.data.message, 
    //         isLoading: false,
    //         status: true,
    //     })
    // }.bind(this))
    // .catch(function (error) {
    //   console.log(error);
    // });
  }
    
  onSend(event) {
    event.preventDefault();

    const url = domain + "/v1/contact";
    var params = new URLSearchParams();
    params.append('contact_name', this.state.contact_name);
    params.append('contact_belong', this.state.contact_belong);
    params.append('contact_email', this.state.contact_email);
    params.append('contact_purpose', this.state.contact_purpose);
    params.append('contact_detail', this.state.contact_detail);

    axios.post(url, params)
    .then(function(response) {
      this.setState({
        contact_name: '',
        contact_belong: '',
        contact_email: '',
        contact_purpose: '',
        contact_detail:'',
        contact_status: 1
      })            
    }.bind(this))
    .catch(function (error) {
      console.log(error);
    }.bind(this));
  }

  render() {
    const loading = (
      <div className="loader">Loading...</div>
    );

    const message = (
      <div className="message_area">
          <TypeWriter typing={0.5}>{ this.state.message }</TypeWriter>
      </div>
    );

    return (
      <div className="App" id="width">
        <div className="hd_navigation">
          <div className="hd_right">
            <ul className="hd_navi_menu">
                <li><a href="#message">メッセージを読む</a></li>
                <li><a href="#items">商品一覧</a></li>
                {/* <li><a href="/concept">コンセプト</a></li> */}
                <li><a href="mailto:info@issho.io?subject=【ISSHO | ホームページから問い合わせ】">お問い合わせ</a></li>
                {/* <li><a href="/contact">お問い合わせ</a></li> */}
            </ul>
          </div>
          <a href="/">
            <div className="hd_logo"></div>
          </a>
        </div>
        <div className="App-header">
          <div className="App-title-box">
            <h1 className="App-title">指輪を通じて特別な言葉を伝える</h1>
            <img className="App-logo" src={logo} alt="logo" />
          </div>
          <div className="hd_down_wrapper">
              <div className="scroll-down">
                <a href="#scroll">
                <p>SCROLL DOWN</p>
                <div className="scroll-icon"></div>
                </a>
              </div>
              <div className="hd_sns">
                <a href="https://www.facebook.com/issho.io/" target="_blank"><div className="facebook"></div></a>
                <a href="https://www.instagram.com/issho.io/" target="_blank"><div className="instagram"></div></a>
                <a href="https://twitter.com/issho_io" target="_blank"><div className="twitter"></div></a>
              </div>
            </div>
        </div>
        <div className="conceptArea" id="scroll">
          <div className="service-left">
            <p className="concept">SERVICE</p>
            <p className="concept-title">
              永遠に記録される、<br/>
              特別な言葉を伝える。
            </p>
            <p className="concept-detail">
              大切な人の指輪に刻まれているアドレス宛に、<br />
              あなたの書き記す特別なメッセージを<br />
              永久に記録することができます。 
            </p>
          </div>
          {/* <div className="service-right">
            <a href="/concept" className="concept_link">
                <p className="concept_link_text">コンセプトを見る</p>
                <div className="arrow"></div>
            </a>
          </div> */}
        </div>
        <div className="movie_message_area">
          <div className="movie_a">
            <p className="concept">MOVIE MESSAGE</p>
            <p className="concept-title">ムービーの<br/>メッセージを読み解く。</p>
            <p className="movie_detail">
              下記のアドレスを打ち込むとムービーに登場する、<br/>
              指輪たちに刻まれたメッセージを読み解くことができます。
            </p>
            {/* <div uk-modal> */}
            <div>
              <div className="uk-modal-dialog uk-width-auto">
                <div className="movie_area">
                  <iframe src="https://www.youtube.com/embed/Ips_c4aED-E" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="contract_address_group" id="message">
              <div className="contract_group">
                <div className="contract_items">
                  <div className="ring_item">
                      <p className="ring_name">01. ピンキーリング</p>
                      <div className="copy">
                        <CopyToClipboard text={this.state.pinky_value}
                          onCopy={() => this.setState({copied: true})}>
                          {/* <button onfocus="this.blur();" className="copy_btn" id="copy_btn"></button> */}
                          <button className="copy_btn" id="copy_btn"></button>
                        </CopyToClipboard>
                      </div>
                  </div>
                <div>
                  <p className="ring_contract">0x63d2d2c6d03b63e2f3a65867befa6881cf6318e6</p>
                </div>
              </div>
                <div className="contract_items">
                  <div className="ring_item">
                      <p className="ring_name">02. 婚約指輪</p>
                      <div className="copy">
                        <CopyToClipboard text={this.state.engage_value}
                          onCopy={() => this.setState({copied: true})}>
                          <button className="copy_btn" id="copy_btn"></button>
                        </CopyToClipboard>
                      </div>
                  </div>
                <div>
                  <p className="ring_contract">0x3f88df9ad7a6fdaa700dd5263853326e4756e62f</p>
              </div>
                </div>
                <div className="contract_items">
                  <div className="ring_item">
                      <p className="ring_name">03. 結婚指輪（男性へ）</p>
                      <div className="copy">
                        <CopyToClipboard text={this.state.marriage_value_men}
                          onCopy={() => this.setState({copied: true})}>
                          <button className="copy_btn" id="copy_btn"></button>
                        </CopyToClipboard>
                      </div>
                  </div>
                <div>
                  <p className="ring_contract">0xa1719023ffa9956e635b28fdcf7ff2a4cf2e933e</p>
              </div>
                </div>
                <div className="contract_items">
                  <div className="ring_item">
                      <p className="ring_name">04. 結婚指輪（女性へ）</p>
                      <div className="copy">
                        <CopyToClipboard text={this.state.marriage_value_women}
                          onCopy={() => this.setState({copied: true})}>
                          {/* <button type="button" onfocus="this.blur();" className="copy_btn" id="copy_btn"></button> */}
                          <button type="button" className="copy_btn" id="copy_btn"></button>
                        </CopyToClipboard>
                      </div>
                  </div>
                <div>
                  <p className="ring_contract">0x0aa70bc57a2ff2750580f242ed77e3ba1bdbdda4</p>
              </div>
                </div>
                <div className="contract_items">
                  <div className="ring_item">
                      <p className="ring_name">05. ベビーリング</p>
                      <div className="copy">
                        <CopyToClipboard text={this.state.baby_value}
                          onCopy={() => this.setState({copied: true})}>
                          <button className="copy_btn" id="copy_btn"></button>
                        </CopyToClipboard>
                      </div>
                  </div>
                <div>
                  <p className="ring_contract">0x1449bffa08d35383fca34c578903f5196015a254</p>
              </div>
                </div>
              </div>
              <div className="message_input_area">
                <form onSubmit={this.onSubmit} className="input_area">
                  <input 
                    type="text" 
                    placeholder="アドレスを入力してください" 
                    className="message_input"
                    ref='value'
                    value={this.state.contractAddress}
                    onChange = { event => this.setState({ contractAddress: event.target.value })}
                    />
                  <Link to={ `/message/show/${this.state.contractAddress}` } ><button type="submit" className="get_message_btn">メッセージを読む</button></Link>
                </form>
              </div> 
          </div>
        </div>
        <div className="rings" ></div>
        { obj.clientWidth > 920 ? <BackWeb id="items"/>: <BackSp id="items"/>}
        <div className="crowdfunding-area">
          <p className="crowdfunding">CROWD FUNDING</p>
          <p className="crowdfunding_title">
            クラウドファンディングへの<br/>
            ご支援ありがとうございました。
          </p>
          <p className="crowdfunding_description">
          パトロン数：52名　支援金総額：320,045円　目標達成率：32%<br/>
          <br/>
          目標に至らずとも、様々な出会いがありました。<br/>
          ありがとうございました。<br/>
          たくさんの人々に、「いっしょう、いっしょ」を届けて、<br/>
          一組でも多くの幸せを作り上げるために、<br/>
          これからも精進して参ります。
          </p>
          <a href="https://camp-fire.jp/projects/view/73111?token=u7f8cifb" className="link">
            <div className="crowdfunding_board">
              <div className="crowdfunding_wrapper">
                <div className="crowdfunding_issho_logo"></div>
                <div className="crowdfunding_versus"></div>
                <div className="crowdfunding_cf_logo"></div>
              </div>
            </div>
          </a>
        </div>
        <div className="footer">
            <a href="/"><div className="footer_logo"></div></a>
            <div className="fotter_menu">
                    <span><a className="fotter_menu_none" id="border_none" href="#message">メッセージを読む</a></span>
                    {/* <span><a className="fotter_menu_left" href="/concept">コンセプト</a></span> */}
                    <span><a className="fotter_menu_left" href="#items">商品一覧</a></span>
                    <span><a className="fotter_menu_left"　href="mailto:info@issho.io?subject=【ISSHO | ホームページから問い合わせ】">お問い合わせ</a></span>
                    {/* <span><a className="fotter_menu_left" href="/contact">お問い合わせ</a></span> */}
                    <span><a className="fotter_menu_left" href="https://company.issho.io">会社概要</a></span>
            </div>
            <div className="sns">
              <a href="https://www.facebook.com/issho.io/" rel="noopener noreferrer"><div className="facebook"></div></a>
              <a href="https://www.instagram.com/issho.io/" rel="noopener noreferrer"><div className="instagram"></div></a>
              <a href="https://twitter.com/issho_io" rel="noopener noreferrer"><div className="twitter"></div></a>
            </div>
            <p className="copy">Copyright © <a href="https://company.issho.io" className="">ISSHO, inc.</a> All Rights Reserved</p>
        </div>
      </div>
  
    )
  }
}

export default Main;